@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    width: 100%;
}

@layer base {
    html {
        font-family: 'Radio Canada', sans-serif;
    }
}